/*.App {*/
/*  text-align: center;*/
/*}*/

.PublicRoute {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 100px 0;
}

.errorMsg {
  color:red!important;
  font-size:16px;
  font-weight:Bold;
}

.login__image{
  text-align:center;
}
.login__logo{
  position:absolute;
  display:flex;
  align-items: flex-start;
}
.login__translate{
  text-align:end;
}

.main-container{
  flex-grow: 1;
}
.element-container{
  padding: 30px;
}
.PrivatePickersToolbar-root button {
  background: #F8F8F8;
}

.MuiDialog-container .MuiDialogActions-root button {
  color:white;
}
.MuiDialog-container button:hover {
  background: #afb7ab;
  color:white;
}
.signatureFormulaire{
  width: 100%;
  height:100px
}

.editor-logo{
  border: 1px solid #ccc;
}

.searchClient div{
    margin: 0;
}
.link__translate{
  text-align:right;
}
.link__translate a:hover{
    color: #000!important;
}
/******* Full Calendar ********/
/*.fc-v-event{*/
/*  background: transparent;*/
/*}*/

/*.fc-v-event .MuiGrid-item{*/
/*  background: blue;*/
/*}*/
.fc-day-past {
  background: #F8F8F8;
}
/* override day number color and size */
.fc-daygrid-day-top {
  font-size: 1.5em;
  color: #62757c;
}

/* override day title color and size */
.fc-col-header-cell {
  font-size: 1.5em;
  color: #62757c;
}

@media screen and (max-width: 780px) {
  .element-container{
    padding: 5px;
  }
}

@media screen and (max-width: 500px) {
  .main-container{
    padding:10px;
  }
}

/******* PRINT ********/
#printingView .MuiPaper-elevation1{
  box-shadow: none;
}

#header-print,
#printerFooter{
    display: none;
}